<template>
  <div id="app">
    <div v-if="loggedin">
      <button hidden @click="signOut">logout</button>
      <div v-for="message in messages">
        <span class="code">{{ message.text }}</span>
        <br />
        <span class="expire">{{ message.expire }}</span>
        <button @click="updateMessage(message)">use</button>
        <hr />
      </div>
      <hr />
      <input ref="newmessage" placeholder="new message ..." type="text" />
      <p></p>
      <select ref="expire">
        <option>TM</option>
        <option>TE</option>
        <option>NM</option>
        <option>NE</option>
      </select>
      <button @click="addNewMessage">addnew</button>
    </div>
    <div v-else>
      <h1>Login to Your Account</h1>
      <p><input type="text" placeholder="Email" ref="email" /></p>
      <p><input type="password" placeholder="Password" ref="password" /></p>
      <p><button @click="signIn">login</button></p>
    </div>
  </div>
</template>

<script>
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";

import {
  getFirestore,
  onSnapshot,
  collection,
  doc,
  deleteDoc,
  setDoc,
  addDoc,
  orderBy,
  query,
} from "firebase/firestore";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { ref, onUnmounted } from "vue";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBeb0iiSA8g4l7yvkjFrLtloue4M3il-rg",
  authDomain: "vuedemo-294c5.firebaseapp.com",
  projectId: "vuedemo-294c5",
  storageBucket: "vuedemo-294c5.appspot.com",
  messagingSenderId: "560213222527",
  appId: "1:560213222527:web:f06c203b8cf7567435cb4a",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
export default {
  name: "App",
  components: {},
  methods: {
    signIn() {
      // メールアドレスとパスワードが入力されているかを確認
      //if (email.value == "" || password.value == "") return;
      const email = this.$refs.email.value;
      const password = this.$refs.password.value;
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // 成功時処理
          const user = userCredential.user;
          console.log(user);
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/invalid-email":
              errMsg.value = "Invalid email";
              break;
            case "auth/user-not-found":
              errMsg.value = "No account with that email was found";
              break;
            case "auth/wrong-password":
              errMsg.value = "Incorrect password";
              break;
            default:
              errMsg.value = "Email or password was incorrect";
              break;
          }
        });
    },
    signOut: function () {
      signOut(auth);
    },
    addNewMessage: function () {
      const today = Date.now();
      var str_array = this.$refs.newmessage.value.split("|");
      for (var i = 0; i < str_array.length; i++) {
        str_array[i] = str_array[i].replace(/^\s*/, "").replace(/\s*$/, "");
        addDoc(collection(db, "messages"), {
          text: str_array[i],
          date: today,
          expire: new Date(today).getMonth() + 1 + this.$refs.expire.value,
        });
      }
      this.$refs.newmessage.value = null;
    },
    updateMessage: function (message) {
      const today = Date.now();
      deleteDoc(doc(db, "messages", message.id));
      addDoc(collection(db, "messagesUsed"), {
        text: message.text,
        date: message.date,
        expire: message.expire,
        usedBy: this.currentuser,
        usedDate: today,
      });
    },
    deleteMessage: function (id, user) {
      deleteDoc(doc(db, "messages", id));
    },
  },
  data: () => {
    return {
      messages: ref([]),
      loggedin: false,
      currentuser: "",
    };
  },
  mounted() {
    const loginListener = auth.onAuthStateChanged((user) => {
      if (user != null) {
        this.loggedin = true;
        this.currentuser = user.email;
        const latestQuery = query(collection(db, "messages"), orderBy("date"));
        const livemessages = onSnapshot(latestQuery, (snapshot) => {
          this.messages = snapshot.docs.map((doc) => {
            return {
              id: doc.id,
              text: doc.data().text,
              date: doc.data().date,
              expire: doc.data().expire,
            };
          });
        });
        onUnmounted(livemessages);
      } else {
        this.loggedin = false;
      }
    });
    onUnmounted(loginListener);
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
input {
  margin: 0px;
}
span.code {
  width: 200px;
  color: black;
  font-size: 30px;
  font-weight: bolder;
}
span.expire {
}
</style>
